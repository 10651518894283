import React, { useState, useEffect } from "react";

const getNextTargetTime = (currentTime, hour, minute) => {
    const nextDay = new Date(currentTime);
    nextDay.setDate(nextDay.getDate() + 1);
    nextDay.setHours(hour, minute, 0, 0);
    return nextDay;
};

const Countdown = ({ targetTime, following }) => {
    const [countdown, setCountdown] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        const calculateCountdown = () => {
            const currentTime = new Date();

            if (currentTime >= targetTime) {
                setCountdown({ hours: 0, minutes: 0, seconds: 0 });
                return false;
            }

            let timeDiff = targetTime.getTime() - currentTime.getTime();
            const hours = Math.floor(timeDiff / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
            const seconds = Math.floor((timeDiff / 1000) % 60);

            setCountdown({ hours, minutes, seconds });
        };

        const timer = setInterval(calculateCountdown, 1000);

        return () => clearInterval(timer);
    }, [targetTime]);

    return (
        <>
            <div className="number">
                {following > 0 ? (
                    <>
                        <div className="item">{following == 7 ? 24 * following : 24 * (following - 1) + countdown.hours}</div>
                    </>
                ) : (
                    <div className="item">{countdown.hours.toString().toLowerCase().padStart(2, "0")}</div>
                )}
                <div className="item">:</div>
                <div className="item">{countdown.minutes.toString().toLowerCase().padStart(2, "0")}</div>
                <div className="item">:</div>
                <div className="item">{countdown.seconds.toString().toLowerCase().padStart(2, "0")}</div>
            </div>
        </>
    );
};

const CountDown = ({ date }) => {
    const currentTime = new Date();
    const day = currentTime.getDate().toString().toLowerCase().padStart(2, "0");
    const following = Number(date.substring(0, 2)) - Number(day);
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();

    let targetTime1, targetTime2;
    if (currentHour < 16 || (currentHour === 16 && currentMinute < 10) || (currentHour === 16 && currentMinute > 50)) {
        targetTime1 = new Date();
        targetTime1.setHours(16, 10, 0, 0);
        targetTime2 = new Date();
        targetTime2.setHours(16, 15, 0, 0);
        /*} else if (currentHour == 16 && currentMinute > 10 && currentMinute < 15) {
        if (following == 0 ) {
            targetTime1 = new Date();
            targetTime2 = new Date();
            targetTime2.setHours(16, 15, 0, 0);
        }*/
    } else if (currentHour > 16) {
        targetTime1 = getNextTargetTime(currentTime, 16, 10);
        targetTime2 = getNextTargetTime(currentTime, 16, 15);
    } else if (following == 0 || following == 7) {
        targetTime1 = new Date();
        targetTime2 = new Date();
    } else {
        targetTime1 = getNextTargetTime(currentTime, 16, 10);
        targetTime2 = getNextTargetTime(currentTime, 16, 15);
    }

    return (
        <>
            <div className="time-box">
                {console.log(targetTime1)}
                <Countdown targetTime={targetTime1} following={following} />
            </div>
        </>
    );
};

export default CountDown;
