import axios from "axios";
import { GetCountry, GetImage, GetDate } from "../../../funcUtils";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import Results from "./0_Results";
import History from "../../components/SidebarHistory";
import CountDown from "./0_countdown";
import TabNavigation from "./0_Tab";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AnimatedPage from "../../components/AnimatedPage";
import { useNavigate, useParams } from "react-router-dom";

function Xoso() {
    const { id } = useParams();
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate("/xoso");
    };
    const [bet, setBet] = useState(JSON.parse(localStorage.getItem(id)));
    const [loadGame, setLoadGame] = useState(false);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [total, setTotal] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [choose, setChoose] = useState([]);
    const [textArea, setTextArea] = useState(null);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios.get(`https://mu88.live/api/front/open/lottery/history/list/1/${id}`).then((res) => {
            setBet(res.data.t);
            if (bet != res.data.t) {
                setBet(res.data.t);
                localStorage.setItem(id, JSON.stringify(res.data.t));
            }
            setTotal([
                {
                    dacbiet: JSON.parse(res.data.t.issueList[0].detail)[0],
                    nhat: JSON.parse(res.data.t.issueList[0].detail)[1],
                    hai: JSON.parse(res.data.t.issueList[0].detail)[2].split(",").join(" "),
                    ba: JSON.parse(res.data.t.issueList[0].detail)[3].split(",").join(" "),
                    tu: JSON.parse(res.data.t.issueList[0].detail)[4].split(",").join(" "),
                    nam: JSON.parse(res.data.t.issueList[0].detail)[5].split(",").join(" "),
                    sau: JSON.parse(res.data.t.issueList[0].detail)[6].split(",").join(" "),
                    bay: JSON.parse(res.data.t.issueList[0].detail)[7].split(",").join(" "),
                    tam: JSON.parse(res.data.t.issueList[0].detail)[8].split(",").join(" ")
                }
            ]);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            if (profile != res.data.data) {
                setProfile(res.data.data);
                localStorage.setItem("profile", JSON.stringify(res.data.data));
            }
        });
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const openPopup1 = () => {
        setIsOpen1(true);
    };
    const closePopup1 = () => {
        setIsOpen1(false);
    };

    const [isOpen2, setIsOpen2] = useState(false);
    const openPopup2 = () => {
        setIsOpen2(true);
    };
    const closePopup2 = () => {
        setIsOpen2(false);
    };
    const fTextarea = (e) => {
        setTextArea(e.target.value.trim().replace(/[^0-9., ]/g, ""));
        const text = e.target.value.trim().replace(/[^0-9., ]/g, "");
        let splitChar;
        if (text.indexOf(" ") !== -1) {
            splitChar = " ";
        } else if (text.indexOf(".") !== -1) {
            splitChar = ".";
        } else if (text.indexOf(",") !== -1) {
            splitChar = ",";
        } else {
            setChoose([text]);
            return false;
        }
        const arr = text.split(splitChar);
        const uniqueArr = arr.filter((item, index) => arr.indexOf(item) === index);
        setChoose(uniqueArr);
        setTextArea(uniqueArr.join(","));
    };
    const onChoose = (e) => {
        if (choose.includes(e.target.id)) {
            setChoose(choose.filter((item) => item !== e.target.id));
        } else if (choose.length < 700) {
            setChoose([...choose, e.target.id]);
        } else {
            swal("Chú ý", `Bạn chỉ được chọn tối đa 700 số`, "warning");
        }
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (loadGame == true) return false;
        setLoadGame(true);
        const newData = [];
        let notAllowed = false;
        choose.map((item) => {
            if (item.length == 1) {
                newData.push("00" + Number(item).toString().toLowerCase());
            } else if (item.length == 2) {
                newData.push("0" + Number(item).toString().toLowerCase());
            } else if (item.length > 3) {
                notAllowed = true;
            } else if (item.length == 3) {
                newData.push(item);
            }
        });
        if (notAllowed) {
            swal("Thông báo", "Danh sách số đánh có số không hợp lệ.", "error");
            return false;
        }
        if (newMoney == 0 || newMoney == null) {
            swal("Thất bại", "Bạn chưa nhập tiền", "error");
            return false;
        }
        if (newMoney * 2000 > profile.money) {
            swal("Thất bại", "Số dư không đủ", "error");
            return false;
        }
        if (newMoney * 2000 < setting?.mincuoc) {
            swal("Chú ý", `Số tiền cược tối thiểu là ${Number(setting?.mincuoc).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        if (newMoney * 2000 > setting?.maxcuoc) {
            swal("Chú ý", `Số tiền cược tối đa là ${Number(setting?.maxcuoc).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        if (!newData || choose.length == 0) {
            swal("Thất bại", "Bạn chưa chọn số đánh", "info");
            return false;
        }
        /*  if (choose.length > 3) {
            swal("Chú ý", `Bạn chỉ được chọn tối đa 3 số`, "warning");
            return false;
        } */
        const currentDate = new Date();
        const minute = currentDate.getMinutes() < 10 ? "0" + currentDate.getMinutes() : currentDate.getMinutes();
        if (
            Number(currentDate.getHours() + "" + minute) >= 1710 &&
            Number(currentDate.getHours() + "" + minute) < 1750 &&
            bet?.issueList[0]?.turnNum.substring(0, 2) == currentDate.getDate().toString().toLowerCase().padStart(2, "0")
        ) {
            swal("Đặt cược không thành công.", " Đã hết thời gian cược", "warning");
            return false;
        } else {
            let formData = {
                state: newData.join(" "),
                sanh: bet.name,
                id: bet.turnNum,
                type: 21,
                money: newData.length * newMoney * 2000
            };
            axios
                .post(`${process.env.REACT_APP_API_URL}/history/chooseXSMB`, formData)
                .then((res) => {
                    swal("Đặt cược thành công", "", "success").then(() => setLoadGame(false));
                    setChoose([]);
                    setTextArea("");
                    axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
                        if (profile != res.data.data) {
                            setProfile(res.data.data);
                            localStorage.setItem("profile", JSON.stringify(res.data.data));
                        }
                    });
                })
                .catch((err) =>
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error").then(() => setLoadGame(false))
                );
        }
    };
    const [newMoney, setNewMoney] = useState(1);
    const numbers = Array.from(Array(1000).keys());

    const [activeOption, setActiveOption] = useState("1");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Miền Trung</h1>
                <div className="history" onClick={openPopup2}>
                    <img src="/images/icon_time_gold.png" alt="" />
                    <span>Lịch sử</span>
                </div>
            </div>
            {bet && (
                <>
                    <AnimatedPage>
                        <div className="game-detail">
                            <img alt="" src={require(`../../../images/3mien/${GetImage(id)}.png`)} />
                            <div>
                                <div className="game-detail-title">{GetCountry(id)}</div>
                                <div className="game-detail-des">{GetDate(id)}</div>
                            </div>
                            <div>
                                <div className="game-detail-des">Lượt xổ: {bet.turnNum}</div>
                                <CountDown date={bet?.turnNum} />
                            </div>
                        </div>
                        <div className="game-result" onClick={openPopup1}>
                            <div className="game-result-left">
                                <img src="/images/icon_time_gold.png" alt="" /> <span>{bet?.issueList[0]?.turnNum}</span>
                            </div>
                            <div className="game-result-center">
                                {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                    <div className="ball">
                                        <img alt="" src={`/images/ball/${x}.png`} />
                                    </div>
                                ))}
                            </div>
                            <div className="game-result-right">
                                <KeyboardArrowDownIcon sx={{ fontSize: "0.6rem" }} />
                            </div>
                        </div>
                        <div style={{ position: "relative" }}>
                            <div className="game-rate">
                                <div onClick={() => setIsOpen(!isOpen)}>
                                    Hình thức cược: 3 càng đầu đuôi{" "}
                                    {isOpen ? (
                                        <KeyboardArrowUpIcon sx={{ fontSize: "0.45rem", marginBottom: "-5px" }} />
                                    ) : (
                                        <KeyboardArrowDownIcon sx={{ fontSize: "0.45rem", marginBottom: "-5px" }} />
                                    )}
                                </div>
                                <div>{setting && "Tỷ lệ cược: 1 ăn " + setting.mn_3cangdauduoi}</div>
                            </div>
                            <div className={isOpen ? "popup-change-game active" : "popup-change-game"}>
                                <TabNavigation id={id} />
                            </div>
                        </div>
                        <div className="main-game">
                            <ul className="tab-navigation tab-game">
                                <li className={activeOption === "1" ? "active" : ""} onClick={() => handleOptionClick("1")}>
                                    Chọn số nhanh
                                </li>
                                <li className={activeOption === "2" ? "active" : ""} onClick={() => handleOptionClick("2")}>
                                    Nhập số
                                </li>
                            </ul>
                            <div className="main_game">
                                {activeOption === "1" && (
                                    <div className="text_choose_center">
                                        <div className="state_choose">
                                            {numbers.map((number) => (
                                                <div key={number} id={number} onClick={onChoose} className={`choose_xs  ${choose.includes(String(number)) ? "chooseItem" : ""}`}>
                                                    {number < 10 ? `00${number}` : number < 100 ? `0${number}` : number}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {activeOption === "2" && (
                                    <div className="text_choose_center">
                                        <div className="cach-choi">
                                            Cách chơi:
                                            <br />
                                            Giữa mỗi cược cần phân cách bởi dấu , hoặc khoảng trống. Ví dụ: 10,20,30 hoặc 10 20 30
                                        </div>
                                        <textarea onChange={fTextarea} value={textArea}></textarea>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="footer-game">
                            <form onSubmit={onSubmit}>
                                <div className="footer-game-top">
                                    <div className="footer-chip">
                                        <div className="chip-1x" onClick={() => setNewMoney(Number(newMoney) + 1)}></div>
                                        <div className="chip-3x" onClick={() => setNewMoney(Number(newMoney) + 3)}></div>
                                        <div className="chip-5x" onClick={() => setNewMoney(Number(newMoney + 5))}></div>
                                        <div className="chip-10x" onClick={() => setNewMoney(Number(newMoney) + 10)}></div>
                                    </div>
                                    <div>
                                        <input value={newMoney} onChange={(e) => setNewMoney(e.target.value)} min="1" name="money" type="number" />
                                    </div>
                                    <div>
                                        Đã chọn: <b style={{ margin: "0 0.15rem" }}>{choose.length}</b> lô
                                    </div>
                                </div>
                                <div className="footer-game-bottom">
                                    <button
                                        type="reset"
                                        className="btn-reset"
                                        style={{ opacity: "0.8" }}
                                        onClick={() => {
                                            setNewMoney(1);
                                            setChoose([]);
                                            setTextArea("");
                                        }}>
                                        Cài lại
                                    </button>
                                    <div className="footer-game-money">
                                        <span>Số tiền:</span> <b>{choose.length != 0 && newMoney ? (choose.length * newMoney * 2000).toLocaleString('vi-VN') : 0}</b>
                                        <br />
                                        Số dư: {Math.floor(profile?.money).toLocaleString('vi-VN')}
                                    </div>
                                    <button type="submit" className="btn-sbmit">
                                        Đặt cược
                                    </button>
                                </div>
                            </form>
                        </div>
                    </AnimatedPage>
                </>
            )}

            <Results isOpen={isOpen1} total={total} closePopup={closePopup1} date={bet?.issueList[0]?.turnNum} country={GetCountry(id)} />

            <History isOpen={isOpen2} closePopup={closePopup2} />
        </>
    );
}
export default Xoso;
