import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import store from "../src/app/store";

ReactDOM.createRoot(document.getElementById("root")).render(
    <Provider store={store}>
        <React.Suspense fallback={<div className="admin-theme">...</div>}>
        <AnimatePresence wait>
            <Router>
                <App />
            </Router>
            </AnimatePresence>
        </React.Suspense>
    </Provider>
);
reportWebVitals();