import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { GetNameChoose } from "../../funcUtils";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";
import swal from "sweetalert";
import DatePicker from "react-datepicker";

function HistoryCasino() {
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [history, setHistory] = useState(null);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}${month}${day}`;
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/historymember`, { date: formattedDate })
            .then((res) => {
                setHistory(res.data.data);
            })
            .catch((err) => function () {});
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    const selectHistory = (date) => {
        if (isLoading == true) return;
        setLoading(true);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}${month}${day}`;
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/historymember`, { date: formattedDate })
            .then((res) => {
                setHistory(res.data.data);
                setLoading(false);
            })
            .then((res) => {
                setLoading(false);
            });
    };
    const productId = (data) => {
        let a;
        switch (data.toString()) {
            case "4":
                a = "AG";
                break;
            case "79":
                a = "BBIN";
                break;
            case "104":
                a = "CMD";
                break;
            case "16":
                a = "CQ9";
                break;
            case "27":
                a = "DG";
                break;
            case "86":
                a = "Evolution";
                break;
            case "142":
                a = "FB";
                break;
            case "140":
                a = "JILI";
                break;
            case "121":
                a = "KingMaker";
                break;
            case "102":
                a = "V8 Poker";
                break;
            case "123":
                a = "King Poker";
                break;
            case "43":
                a = "MG";
                break;
            case "39":
                a = "PP";
                break;
            case "174":
                a = "SABA";
                break;
            case "118":
                a = "WM";
                break;
            case "112":
                a = "SEXY";
                break;
            case "54":
                a = "SBO";
                break;
            case "151":
                a = "UG";
                break;
            case "99":
                a = "TF";
                break;
            case "111":
                a = "Playtech";
                break;
            case "28":
                a = "AllBet";
                break;
            case "55":
                a = "JDB";
                break;
            case "202":
                a = "WS168";
                break;
            default:
                a = "";
                break;
        }
        return a;
    };
    return (
        <>
            {isLoading ? (
                <div className="loading" style={{ maxWidth: "100%" }}>
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Lịch sử game</h1>
            </div>
            <div className="main">
                {history != null ? (
                    <div className="content-history" style={{ margin: "0.2rem 0 0" }}>
                        <AnimatedPage>
                            <DatePicker
                                maxDate={new Date()}
                                selected={endDate}
                                onChange={(date) => {
                                    setEndDate(date);
                                    selectHistory(date);
                                }}
                            />
                            {history?.map((item, key) => (
                                <>
                                    <div
                                        className="item_inner"
                                        onClick={() => {
                                            setLs(item);
                                            setShow(true);
                                        }}>
                                        <div className="item_history">
                                            <div className="title_item_history">
                                                <span className="sanh">{item?.gameName}</span>
                                                <span className={`type_state win`} style={{background:"orange"}}>{productId(item?.productType)}</span>
                                            </div>
                                            <div className="id_history_sanh">{formatDate(new Date(item?.betTime))}</div>
                                        </div>
                                        <div className="money_history">
                                            <span className="money">Cược {item?.betAmount.toLocaleString("vi-VN")}₫</span>
                                            <span className="money">Thắng {item?.winAmount.toLocaleString("vi-VN")}₫</span>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </AnimatedPage>
                    </div>
                ) : null}

                <Footer profile={profile} />
            </div>
        </>
    );
}
export default HistoryCasino;
